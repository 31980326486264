import {Card} from '../../../../../app/components/card/Card';
import {Autocomplete, DateInput, TextField} from '@brightsource/brightsource-ui-lib';
import {autcompleteComparator, renderOptionByValue} from '../../../../../shared/utils/form.utils';
import React, { useCallback, useEffect, useState } from 'react';
import './ProcessFilterBar.scss';
import {observer} from 'mobx-react';
import {processReportStore} from '../../report.store';
import {formatFromDate, formatToDate} from '../../../../../shared/models';
import { debounce } from 'lodash';
import { Button } from '@blueprintjs/core';
import { useSearchCompanies } from '../../../../../services/search.service';
import jobStore from '../../../../../stores/job.store';

export const ProcessFilterBar = observer(() => {
  const { interviewer, companyHandler, jobHandler, sender, fromDate, toDate, jobStatus, candidateAvailability, stage } = processReportStore.properties;
  const {
    companyHandler: recruiterNameOptions = [],
    jobStatus: jobStatusOptions = [],
    candidateAvailability: candidateAvailabilityOptions = [],
    stage: stageOptions = []
  } = processReportStore.source;
  const [searchCandidateName, setSearchCandidateName] = useState('');
  const [searchCompanyName, setSearchCompanyName] = useState(null);
  const [searchJobName, setSearchJobName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { companies, isSearching, onSearchDebounce } = useSearchCompanies();

  const debounceHandler = (filterName) =>{
    return debounce((value) => {
      processReportStore.loadByFilter(filterName, value);
    }, 500);
  }

  const handleCandidateNameChange = useCallback(debounceHandler('candidateName'), []);

  const handleClearFilter = () => {
    processReportStore.resetFilters();
    setSearchCandidateName('');
    setSearchCompanyName(null);
    onSearchDebounce('');
    setSearchJobName(null);
  }

  const handleCompanySelection = async (company) => {
    setSearchCompanyName(company);
    setIsLoading(true);
    if(company){
      await jobStore.searchJobsWithMatches({ company: company._id }, false);

      const jobsIds = jobStore.searchedJobs.map((job) => job._id);
      await jobStore.searchJobsByIds(jobsIds.filter(Boolean));
    }
    processReportStore.loadByFilter('companyId', company?._id ?? null);
    setIsLoading(false);
  }

  const searchedJobs = jobStore.searchedJobs.map((searchedJob) => {
    const jobs = Object.values(jobStore.jobs);
    const foundJob = jobs.find((job) => job._id === searchedJob._id);

    if (foundJob) return { ...foundJob, elId: foundJob._id, value: foundJob.title };
    return null;
  });

  const job = jobStore.jobToShow;

  console.log(job)

  return (
    <Card>
      <div className='clear-filter'>
        <Button minimal intent='primary' onClick={handleClearFilter}>
          Clear All
        </Button>
      </div>
      <div className={"process-filter-bar"}>
        <Autocomplete
          label='Interviewer'
          options={recruiterNameOptions}
          onChange={(_, value) => processReportStore.loadByFilter("interviewer", value)}
          value={interviewer ?? []}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
          multiple
        />

        <Autocomplete
          label='Company handler'
          options={recruiterNameOptions}
          onChange={(_, value) => processReportStore.loadByFilter("companyHandler", value)}
          value={companyHandler ?? []}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
          multiple
        />

        <Autocomplete
          label='Job handler'
          options={recruiterNameOptions}
          onChange={(_, value) => processReportStore.loadByFilter("jobHandler", value)}
          value={jobHandler ?? []}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
          multiple
        />

        <Autocomplete
          label='Sender'
          options={recruiterNameOptions}
          onChange={(_, value) => processReportStore.loadByFilter("sender", value)}
          value={sender ?? []}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
          multiple
        />

        <Autocomplete
          label='Stage'
          options={stageOptions}
          onChange={(_, value) => processReportStore.loadByFilter("stage", value)}
          value={stage ?? null}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
        />

        <TextField
          label='Candidate Name'
          value={searchCandidateName}
          onChange={(e) => {
            setSearchCandidateName(e.target.value)
            handleCandidateNameChange(e.target.value)
          }}
          placeholder='Search'
        />

        <Autocomplete
          label='Company Name'
          options={companies}
          getOptionLabel={(x) => x.name}
          isOptionEqualToValue={(a, b) => a._id === b._id}
          value={searchCompanyName ?? null}
          onChange={(_, value) => {
            handleCompanySelection(value);
          }}
          placeholder='Search'
          disableFilterOptions
          loading={isSearching}
          onInputChange={(_event, value) => onSearchDebounce(value)}
        />

        <Autocomplete
          label='Job Name'
          options={searchedJobs.filter(Boolean)}
          onChange={(_, value) => {
            setSearchJobName(value)
            processReportStore.loadByFilter('jobId', value?._id)
          }
          }
          value={searchJobName}
          getOptionLabel={(x) => x.title}
          isOptionEqualToValue={autcompleteComparator}
          loading={isLoading}
        />

        <Autocomplete
          label='Job status'
          options={jobStatusOptions}
          onChange={(_, value) => processReportStore.loadByFilter("jobStatus", value)}
          value={jobStatus ?? null}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
        />

        <Autocomplete
          label='Availibility'
          options={candidateAvailabilityOptions}
          onChange={(_, value) => processReportStore.loadByFilter("candidateAvailability", value)}
          value={candidateAvailability ?? null}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
        />

        <DateInput
          label='From date'
          maxDate={new Date()}
          value={fromDate}
          onChange={(date) => processReportStore.loadByFilter("fromDate", formatFromDate(date).toDate())}
        />

        <DateInput
          label='To date'
          maxDate={new Date()}
          value={toDate}
          onChange={(date) => processReportStore.loadByFilter("toDate", formatToDate(date).toDate())}
        />
      </div>
    </Card>
  )
})
