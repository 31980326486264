import userStore from '../../../stores/user.store';
import data from '../../../constants/data';
import {reportApiClient} from './report-api.client';
import {mapUsersToHandlers} from '../../users';
import utilsService from '../../../services/utils.service';
import {ApiOverviewEngine, formatFromDate, formatToDate} from '../../../shared/models';
import {QueryParamUtils} from '../../../shared/utils/params.utils';

async function getUserHandlers() {
  await userStore.getRecruiters();

  return mapUsersToHandlers(Object.values(userStore.recruitersList));
}

export const pipelineReportStore = new ApiOverviewEngine({
  propertiesRegistry: {
    recruiterId: {
      type: 'autocomplete',
      options: getUserHandlers,
    },
    fromDate: {
      type: 'datepicker',
      default: formatFromDate(new Date()).toDate()
    },
    toDate: {
      type: 'datepicker',
      default: formatToDate(new Date()).toDate()
    },
    stage: {
      type: 'autocomplete',
      options: data.stage.filter(item => [3, 13, 4, 5, 6, 7, 8].includes(item.elId))
    },
    limit: {
      default: 20,
    },
    page: {
      default: 0,
    }
  },
  apiCaller: props => reportApiClient.getPipelineReport({
    ...props,
    page: props.page + 1,
    fromDate: props.fromDate?.getTime() ?? undefined,
    toDate: props.toDate?.getTime() ?? undefined,
    recruiterId: props.recruiterId ? QueryParamUtils.packMany(props.recruiterId.map(i => i.elId)) : undefined,
    stage: props.stage ? utilsService.getStages([props.stage.elId])[0] : undefined,
  }),
});

export const processReportStore = new ApiOverviewEngine({
  propertiesRegistry: {
    companyHandler: {
      type: 'autocomplete',
      options: getUserHandlers,
    },
    interviewer: {
      type: 'autocomplete',
    },
    jobHandler: {
      type: 'autocomplete',
    },
    sender: {
      type: 'autocomplete',
    },
    jobStatus: {
      type: 'autocomplete',
      options: data.jobStatus,
      default: data.jobStatus.find(item => item.elId === 1),
    },
    candidateAvailability: {
      type: 'autocomplete',
      options: data.availabilityOptionsForCandidates,
      default: data.availabilityOptionsForCandidates.find(item => item.elId === 0) // Available
    },
    fromDate: {
      type: 'datepicker',
      default: null
    },
    toDate: {
      type: 'datepicker',
      default: null
    },
    stage:{
      type: 'autocomplete',
      options: data.stage.filter(item => [3, 13, 4, 5, 6, 7, 8, 11].includes(item.elId))
    },
    jobRecruiter: {
      type: 'autocomplete',
    },
    candidateName: {
      default: '',
    },
    companyId: {
      default: null,
    },
    jobId: {
      default: null,
    },
    limit: {
      default: 50,
    },
    page: {
      default: 0,
    }
  },
  apiCaller: props => reportApiClient.getProcessReport({
    ...props,
    page: props.page + 1,
    interviewer: props.interviewer ? QueryParamUtils.packMany(props.interviewer.map(i => i.elId)) : undefined,
    fromDate: props.fromDate ? utilsService.getStartDay(props.fromDate) : undefined,
    toDate: props.toDate ? utilsService.getEndDay(props.toDate) : undefined,
    jobHandler: props.jobHandler ? QueryParamUtils.packMany(props.jobHandler.map(i => i.elId)) : undefined,
    companyHandler: props.companyHandler ? QueryParamUtils.packMany(props.companyHandler.map(i => i.elId)) : undefined,
    sender: props.sender ? QueryParamUtils.packMany(props.sender.map(i => i.elId)) : undefined,
    jobStatus: props.jobStatus?.elId,
    candidateAvailability: props.candidateAvailability?.elId,
    stage: props.stage ? utilsService.getStages([props.stage.elId]) : undefined,
    candidateName: props.candidateName?.length > 0 ? props.candidateName : undefined,
    companyName: props.companyName?.length > 0 ? props.companyName : undefined,
    jobTitle: props.jobTitle?.length > 0 ? props.jobTitle : undefined,
    jobRecruiter: props.jobRecruiter?.elId,
    jobId: props.jobId ? props.jobId : undefined,
    companyId: props.companyId ? props.companyId : undefined
  }),
});

export const bonusReportStore = new ApiOverviewEngine({
  propertiesRegistry: {
    recruiterId: {
      type: 'autocomplete',
      options: getUserHandlers,
    },
    limit: {
      default: 50,
    },
    page: {
      default: 0,
    },
    projectType: {
      default: null,
      options: data.projectTypes,
    },
    status: {
      default: null,
      options: [],
    },
    paymentMonth: {
      default: null,
    }
  },
  apiCaller: props => reportApiClient.getBonusReport({
    ...props,
    page: props.page + 1,
    recruiterId: props.recruiterId?.elId,
    projectType: props.projectType?.elId,
    paymentMonth: props.paymentMonth,
  }),
});
