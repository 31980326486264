const getSalaryMonthlyData = () => {
    let i;
    let salaryList = [];
    let salary = 5000;

    for (i = 0; i < 96; i++) {
        let tempSalary = {};
        tempSalary.elId = i;
        tempSalary.value = salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        salaryList.push(tempSalary);
        salary = salary + 1000;
    }
    return salaryList;
};

const getSalaryHourlyData = () => {
    let i = 0;
    return [
        { elId: i++, value: 30 }, { elId: i++, value: 40 }, { elId: i++, value: 50 },
        { elId: i++, value: 60 }, { elId: i++, value: 70 }, { elId: i++, value: 80 },
        { elId: i++, value: 90 }, { elId: i++, value: 100 }, { elId: i++, value: 120 },
        { elId: i++, value: 150 }, { elId: i++, value: 180 }, { elId: i++, value: 200 },
        { elId: i++, value: 250 }, { elId: i++, value: 300 }, { elId: i++, value: 350 },
        { elId: i++, value: 400 }, { elId: i++, value: 450 }, { elId: i, value: 500 }
    ]
};

function buildNewCommissions() {
    const items = [];

    for (let i = 80; i <= 400; i+=5) {
        items.push({
            elId: i,
            value: `${i}%`
        })
    }

    return items;
}

const data = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  company_industry: [
    "Biotech",
    "Equipment",
    "Semiconductor",
    "Services",
    "HR Services",
    "Civil Engineering",
    "Software",
    "Industrials",
    "Cleantech",
    "Wholesale",
    "Internet",
    "Communication",
    "Other",
  ],
  company_type: [
    "Unknown",
    "Privately Held",
    "Publicly Held",
    "State-Owned",
    "Non-Profit",
  ],
  applicant_table_options: [
    "All",
    "Leads",
    "New",
    "In-progress",
    "Interview",
    "Offered",
    "Hired",
    "Decline",
    "Withdrawn",
  ],
  applicant_table_options_single_job: [
    "All",
    "Leads",
    "New",
    "In-progress",
    "Interview",
    "Offered",
    "Hired",
    "Decline",
    "Withdrawn",
  ],
  progress_indicator_statuses: [
    "New",
    "In-progress",
    "Interview",
    "Offer",
    "Hired",
  ],
  statuses: [
    76, 12, 3, 111, 34, 2, 9, 66, 4, 5, 36, 361, 362, 363, 40, 42, 46, 461, 462,
    463, 29, 291, 6, 7, 69, 70, 1, 10, 64, 100,
  ],
  decline_options_recruiter_side: [
    {
      reason: "Unable To Contact",
      id: 42,
    },
    {
      reason: "Already In Process",
      id: 40,
    },
    {
      reason: "Unqualified",
      id: 46,
    },
    {
      reason: "Position Filled",
      id: 5,
    },
    {
      reason: "Salary Mismatch",
      id: 461,
    },
    {
      reason: "Location",
      id: 462,
    },
    {
      reason: "Position On Hold/Cancelled",
      id: 463,
    },
    {
      reason: "Decline After Interview",
      id: 29,
    },
    {
      reason: "Other",
      id: 291,
    },
  ],
  decline_options_candidate_side: [
    {
      reason: "Not Interested In Job",
      id: 36,
    },
    {
      reason: "Not Interested In Salary",
      id: 361,
    },
    {
      reason: "Failed To Show For Interview",
      id: 362,
    },
    {
      reason: "Offer Rejected By Candidate",
      id: 4,
    },
    {
      reason: "Accepted Another Offer",
      id: 363,
    },
    {
      reason: "Remain in his current Position",
      id: 100,
    },
  ],
  close_options_recruiter_side: [
    {
      reason: "Position Filled",
      id: 5,
    },
    {
      reason: "Position On Hold/Cancelled",
      id: 463,
    },
    {
      reason: "Decline After Interview",
      id: 29,
    },
    {
      reason: "Other",
      id: 29,
    },
  ],
  close_options_candidate_side: [
    {
      reason: "Remain in his current Position",
      id: 100,
    },
    {
      reason: "Accepted Another Offer",
      id: 363,
    },
    {
      reason: "Hired",
      id: 7,
    },
  ],
  close_options_candidate_side_change_availability: [
    {
      reason: "Remain in his current Position",
      id: 100,
    },
    {
      reason: "Accepted Another Offer",
      id: 363,
    },
    {
      reason: "Hired",
      id: 7,
    },
    {
      reason: "We haven't found any job  (Automatic email to talent)",
      id: 64,
    },
  ],
  unmatch_options: [
    {
      reason: "Education",
      id: 831,
    },
    {
      reason: "Salary",
      id: 832,
    },
    {
      reason: "Location",
      id: 833,
    },
    {
      reason: "Skills",
      id: 834,
    },
    {
      reason: "Role",
      id: 835,
    },
    {
      reason: "Job Type",
      id: 836,
    },
    {
      reason: "Culture",
      id: 837,
    },
    {
      reason: "Other",
      id: 105,
    },
  ],
  pauseProcess_options: [
    "Examining other candidates",
    "One of the decision makers is unavailable",
    "Gathering additional information regarding the candidate",
    "Waiting for answer from other(preferred) candidate",
    "Other",
  ],
  pipeline_tabs_text: {
    CandidateFor: "Applied for",
    Talent: "Talent",
    Update: "Update",
    All: "All",
  },
  salary_types: [
    {
      elId: 1,
      value: "Monthly",
      sign: "month",
    },
    {
      elId: 2,
      value: "Hourly",
      sign: "hour",
    },
  ],
  currency: [
    {
      elId: 1,
      value: "₪",
    },
    {
      elId: 2,
      value: "$",
    },
  ],
  salaryMonthlyData: getSalaryMonthlyData(),
  salaryHourlyData: getSalaryHourlyData(),
  prefered_langs: [2, 1, 8, 5, 3, 4],
  employers_permissions: [
    { elId: 0, value: "Not Activated" },
    // { elId: 1, value: 'Coordinator' },
    { elId: 2, value: "Recruiter" },
    // { elId: 3, value: 'Team member' },
    { elId: 4, value: "Hiring manager" },
    { elId: 5, value: "Admin" },
  ],
  availabilityOptions: [
    {
      elId: 1,
      value: "All",
    },
    {
      elId: 2,
      value: "Yes",
    },
    {
      elId: 3,
      value: "No",
    },
    {
      elId: 4,
      value: "Maybe",
    },
  ],
  availabilityOptionsSearch: [
    {
      elId: 1,
      value: "Yes",
    },
    {
      elId: 0,
      value: "No",
    },
    {
      elId: 2,
      value: "Maybe",
    },
  ],
  LinkedinOptions: [
    {
      elId: 1,
      value: "All",
    },
    {
      elId: 2,
      value: "With",
    },
    {
      elId: 3,
      value: "Without",
    },
  ],
  availabilityOptionsForProfile: [
    {
      elId: 1,
      value: "Yes",
    },
    {
      elId: 0,
      value: "No",
    },
  ],

  availabilityOptionsForCandidates: [
    {
      elId: 0,
      value: "Available",
    },
    {
      elId: 1,
      value: "Maybe Available",
    },
    {
      elId: 2,
      value: "Unavailable",
    },
  ],

  availabilityOptionsForSearchResults: [
    {
      elId: -1,
      value: "All",
    },
    {
      elId: 0,
      value: "Available",
      class: "available",
    },
    {
      elId: 1,
      value: "Maybe Available",
      class: "maybe",
    },
    {
      elId: 2,
      value: "Unavailable",
      class: "unavailable",
    },
  ],

  statusOptionsForSearchResults: [
    {
      elId: -1,
      value: "All",
    },
    {
      elId: 1,
      value: "Open",
      class: "open",
    },
    {
      elId: 2,
      value: "Hold",
      class: "hold",
    },
    {
      elId: 0,
      value: "Close",
      class: "close",
    },
  ],

  schoolTypeOptions: [
    {
      elId: 1,
      value: "All",
    },
    {
      elId: 3,
      value: "University",
    },
    {
      elId: 7,
      value: "College",
    },
  ],
  benefits: [
    {
      elId: 1,
      value: "Dog friendly",
    },
    {
      elId: 2,
      value: "Game room",
    },
    {
      elId: 3,
      value: "Gym",
    },
    {
      elId: 4,
      value: "Meal plan",
    },
    {
      elId: 5,
      value: "Happy hour",
    },
    {
      elId: 6,
      value: "Volunteering opportunities",
    },
    {
      elId: 7,
      value: "Parent friendly",
    },
    {
      elId: 8,
      value: "Flex hours",
    },
  ],
  filesFormat: {
    1: {
      elId: 1,
      value: "docx",
    },
    2: {
      elId: 2,
      value: "pdf",
    },
    3: {
      elId: 3,
      value: "docx, pdf",
    },
  },
  paymentConditions: {
    0: {
      elId: 0,
      value: "45 days",
    },
    17: {
      elId: 17,
      value: "Current + 30",
    },
    18: {
      elId: 18,
      value: "30 days",
    },
    22: {
      elId: 22,
      value: "60 days",
    },
    23: {
      elId: 23,
      value: "45 days",
    },
    24: {
      elId: 24,
      value: "40 days",
    },
    25: {
      elId: 25,
      value: "Current + 60",
    },
    26: {
      elId: 26,
      value: "Current + 45",
    },
    27: {
      elId: 27,
      value: "90 days",
    },
    28: {
      elId: 28,
      value: "Not defined",
    },
  },
  activitiesUpdates: [
    {
      elId: 0,
      value: "Last",
    },
    {
      elId: 1,
      value: "All",
    },
  ],
  statusAvailable: [
    {
      elId: 0,
      value: "All",
    },
    {
      elId: 1,
      value: "Open",
    },
    {
      elId: 2,
      value: "Close",
    },
  ],
  status: [
    {
      elId: 0,
      value: "All",
    },
    {
      elId: 1,
      value: "Open",
    },
    {
      elId: 2,
      value: "Hold",
    },
    {
      elId: 3,
      value: "Close",
    },
  ],
  stage: [
    {
      elId: 0,
      value: "Leads",
    },
    {
      elId: 1,
      value: "Suggested",
    },
    {
      elId: 2,
      value: "Sourced",
    },
    {
      elId: 3,
      value: "New",
    },
    {
      elId: 13,
      value: "Phone interview",
    },
    {
      elId: 4,
      value: "Request",
    },
    {
      elId: 5,
      value: "Exposed",
    },
    {
      elId: 6,
      value: "Interview",
    },
    {
      elId: 7,
      value: "Offered",
    },
    {
      elId: 8,
      value: "Hired",
    },
    {
      elId: 9,
      value: "Hold",
    },
    {
      elId: 10,
      value: "Withdrawn",
    },
    {
      elId: 11,
      value: "Decline",
    },
    {
      elId: 12,
      value: "Waiting to upload",
    },
  ],
  activitiesStageList: [
    {
      value: "Stage",
      elId: -1,
    },
    {
      value: "New",
      elId: 1,
    },
    {
      value: "In-Progress",
      elId: 2,
      subItems: [
        { elId: 21, value: "Request" },
        { elId: 22, value: "Exposed" },
      ],
    },
    {
      value: "Interview",
      elId: 3,
    },
    {
      value: "Offered",
      elId: 4,
    },
    {
      value: "Hired",
      elId: 5,
    },
    {
      value: "Withdrawn",
      elId: 6,
    },
    {
      value: "Decline",
      elId: 7,
    },
    {
      value: "Leads",
      elId: 8,
      // subItems: ['New', 'Declined', 'Withdrown', 'In-Progress']
    },
  ],
  acitivitiesAvailableStatusList: [
    {
      elId: 0,
      value: "All",
    },
    {
      elId: 1,
      value: "Yes",
    },
    {
      elId: 2,
      value: "No",
    },
  ],
  matchesAvailableStatusList: [
    {
      elId: 1,
      value: "Yes",
    },
    {
      elId: 2,
      value: "No",
    },
  ],
  acitivitiesStatusList: [
    {
      value: "Job",
    },
    {
      value: "Open",
    },
    {
      value: "Hold",
    },
    {
      value: "Close",
    },
  ],
  tags: [
    {
      elId: 1,
      value: "Untitled",
    },
    {
      elId: 2,
      value: "Request Details",
    },
    {
      elId: 3,
      value: "New",
    },
    {
      elId: 4,
      value: "Closed",
    },
    {
      elId: 5,
      value: "Updated",
    },
    {
      elId: 6,
      value: "Accept",
    },
    {
      elId: 7,
      value: "Decline",
    },
    {
      elId: 8,
      value: "Withdrown",
    },
    {
      elId: 9,
      value: "Available",
    },
    {
      elId: 10,
      value: "Unavailable",
    },
    {
      elId: 11,
      value: "Hired",
    },
    {
      elId: 12,
      value: "Offer",
    },
    {
      elId: 13,
      value: "Interview",
    },
    {
      elId: 666,
      value: "Spam",
    },
  ],
  jobStatus: [
    {
      elId: 1,
      value: "Open",
    },
    {
      elId: 2,
      value: "Hold",
    },
    {
      elId: 0,
      value: "Close",
    },
  ],
  jobPublished: [
    {
      elId: 1,
      value: "Publish",
      stroke: "#34c35f",
    },
    {
      elId: 2,
      value: "Discreet",
    },
    {
      elId: 0,
      value: "Unpublish",
      stroke: "#f75245",
    },
  ],
  defaultCompanyMainFilterScreen: {
    jobSourceType: { elId: -1, value: "All" },
    company: { elId: -1, value: "Company" },
    hiringManager: { elId: -1, value: "Hiring Manager" },
    title: { elId: -1, value: "Title" },
    jobStatus: { elId: -1, value: "Status" },
    profileAvailablity: { elId: -1, value: "Availablity" },
    lastUpdatedStatus: { elId: -1, value: "Last Updated Status" },
    processes: { elId: -1, value: "Processes" },
    matches: { elId: -1, value: "Matches" },
    hotJob: { elId: -1, value: "All" },
  },
  jobSourceType: [
    {
      elId: -1,
      value: "All",
    },
    {
      elId: 0,
      value: "Internal",
    },
    {
      elId: 1,
      value: "External",
    },
  ],
  hotJob: [
    {
      elId: -1,
      value: "All",
    },
    {
      elId: 0,
      value: "Yes",
    },
    {
      elId: 1,
      value: "No",
    },
  ],

  preDefinedInboxMessages: [
    {
      elId: 1,
      value: "Great job",
    },
    {
      elId: 2,
      value: "Sorry they were not interested",
    },
    {
      elId: 3,
      value: "Thank you! ",
    },
  ],

  processesFilter: [
    {
      elId: -1,
      value: "All",
    },
    {
      elId: 1,
      value: "With Processes",
    },
    {
      elId: 0,
      value: "Without Processes",
    },
  ],
  matchesFilter: [
    {
      elId: -1,
      value: "All",
    },
    {
      elId: 1,
      value: "With Matches",
    },
    {
      elId: 0,
      value: "Without Matches",
    },
  ],
  lastUpdatedStatus: [
    {
      elId: 0,
      value: "Today",
    },
    {
      elId: 7,
      value: "Last 7 days",
    },
    {
      elId: 30,
      value: "Last 30 days",
    },
    {
      elId: 90,
      value: "Last 90 days",
    },
  ],
  companyManagementSystemsMap: [
    {
      elId: 0,
      value: "icims talent platform",
    },
    {
      elId: 1,
      value: "bamboo hr",
    },
    {
      elId: 2,
      value: "bullhorn",
    },
    {
      elId: 3,
      value: "comeet",
    },
    {
      elId: 4,
      value: "greenhouse",
    },
    {
      elId: 5,
      value: "icims",
    },
    {
      elId: 6,
      value: "jazzhr",
    },
    {
      elId: 7,
      value: "jobscore",
    },
    {
      elId: 8,
      value: "jobvite",
    },
    {
      elId: 9,
      value: "kenexa",
    },
    {
      elId: 10,
      value: "lever",
    },
    {
      elId: 11,
      value: "linked in recruiter/talent solutions",
    },
    {
      elId: 12,
      value: "manatal",
    },
    {
      elId: 13,
      value: "newton software",
    },
    {
      elId: 14,
      value: "niloosoft",
    },
    {
      elId: 15,
      value: "redmatch",
    },
    {
      elId: 16,
      value: "smartrecruiters",
    },
    {
      elId: 17,
      value: "successfactors",
    },
    {
      elId: 18,
      value: "taleez",
    },
    {
      elId: 19,
      value: "taleo recruiting",
    },
    {
      elId: 20,
      value: "the resumator",
    },
    {
      elId: 21,
      value: "workable",
    },
    {
      elId: 22,
      value: "workday",
    },
    {
      elId: 23,
      value: "workforce central by kronos",
    },
    {
      elId: 24,
      value: "zartis online social recruiting software",
    },
    {
      elId: 25,
      value: "zoho recruit",
    },
    {
      elId: 26,
      value: "adam total",
    },
    {
      elId: 322,
      value: "System with email and password",
    },
    {
      elId: 444,
      value: "Other",
    },
  ],
  companyManagementAliasesMap: [
    {
      elId: 0,
      value: "icims talent platform",
    },
    {
      elId: 1,
      value: "bamboo hr",
    },
    {
      elId: 2,
      value: "bullhorn",
    },
    {
      elId: 3,
      value: "comeet",
    },
    {
      elId: 4,
      value: "greenhouse",
    },
    {
      elId: 5,
      value: "icims",
    },
    {
      elId: 6,
      value: "jazzhr",
    },
    {
      elId: 7,
      value: "jobscore",
    },
    {
      elId: 8,
      value: "jobvite",
    },
    {
      elId: 9,
      value: "kenexa",
    },
    {
      elId: 10,
      value: "lever",
    },
    {
      elId: 11,
      value: "linked in recruiter/talent solutions",
    },
    {
      elId: 12,
      value: "manatal",
    },
    {
      elId: 13,
      value: "newton software",
    },
    {
      elId: 14,
      value: "niloosoft",
    },
    {
      elId: 15,
      value: "redmatch",
    },
    {
      elId: 16,
      value: "smartrecruiters",
    },
    {
      elId: 17,
      value: "successfactors",
    },
    {
      elId: 18,
      value: "taleez",
    },
    {
      elId: 19,
      value: "taleo recruiting",
    },
    {
      elId: 20,
      value: "the resumator",
    },
    {
      elId: 21,
      value: "workable",
    },
    {
      elId: 22,
      value: "workday",
    },
    {
      elId: 23,
      value: "workforce central by kronos",
    },
    {
      elId: 24,
      value: "zartis online social recruiting software",
    },
    {
      elId: 25,
      value: "zoho recruit",
    },
    {
      elId: 26,
      value: "adam total",
    },
    {
      elId: 444,
      value: "Other",
    },
  ],
  profileAvailability: [
    {
      elId: 1,
      value: "Available",
    },
    {
      elId: 0,
      value: "Not Available",
    },
  ],
  recruiterTypes: [
    {
      elId: 1,
      value: "Recruiter In Charge",
    },
    {
      elId: 2,
      value: "Placement Recruiter",
    },
    {
      elId: 3,
      value: "First Recruiter",
    },
    {
      elId: 4,
      value: "Top Recruiter",
    },
  ],
  projectsOptions: [
    {
      elId: "0",
      value: "Best Project",
    },
    {
      elId: "1",
      value: "Ipsum",
    },
    {
      elId: "2",
      value: "Lorem",
    },
    {
      elId: "3",
      value: "My Project",
    },
    {
      elId: "4",
      value: "Create New",
    },
  ],
  yesNo: [
    {
      elId: 1,
      value: "No",
    },
    {
      elId: 2,
      value: "Yes",
    },
  ],
  summary: [
    {
      elId: -1,
      value: "All",
    },
    {
      elId: 1,
      value: "Yes",
    },
    {
      elId: 0,
      value: "No",
    },
  ],
  operators: [
    {
      elId: 1,
      value: "In",
    },
    {
      elId: 2,
      value: "All",
    },
  ],
  conversationsSort: [
    {
      elId: 1,
      value: "Last Update",
    },
    {
      elId: 2,
      value: "A-Z",
    },
  ],
  projectTypes: [
    {
      value: "Placement",
      elId: 1,
    },
    {
      value: "Retainer",
      elId: 2,
    },
    {
      value: "Executive Search",
      elId: 3,
    },
    {
      value: "Top Talents",
      elId: 4,
    },
    {
      value: "Payroll",
      elId: 5,
    },
    {
      value: "Private HR",
      elId: 6,
    },
    {
      value: "Search Project",
      elId: 7,
    },
  ],

  domains: [
    { value: "ALGO", elId: 17 },
    { value: "BIO/ENG", elId: 1 },
    { value: "EXEC", elId: 5 },
    { value: "HW", elId: 6 },
    { value: "IT/DATA", elId: 7 },
    { value: "PHR", elId: 16 },
    { value: "PPL", elId: 8 },
    { value: "QA", elId: 9 },
    { value: "RETAIL", elId: 14 },
    { value: "SM/TCF", elId: 11 },
    { value: "SW-HIGH", elId: 13 },
    { value: "SW-LOW", elId: 3 },
    { value: "TOP", elId: 15 },
  ],

  profitCenters: [
    { elId: 1, value: "SUCCESS" },
    { elId: 4, value: "First" },
    { elId: 5, value: "Top" },
    { elId: 6, value: "PHR" },
    { elId: 7, value: "Top-Success" },
  ],

  commissionOptions: [
    { elId: 80, value: "80%" },
    { elId: 85, value: "85%" },
    { elId: 90, value: "90%" },
    { elId: 95, value: "95%" },
    { elId: 100, value: "100%" },
    { elId: 110, value: "110%" },
    { elId: 120, value: "120%" },
    { elId: 150, value: "150%" },
  ],
  newCommissionOptions: buildNewCommissions(),

  variableElement: [
    { elId: 0, value: "#name#" },
    { elId: 1, value: "#firstname#" },
    { elId: 2, value: "#lastname#" },
    { elId: 3, value: "#sendername#" },
    { elId: 4, value: "#senderfirstname#" },
    { elId: 5, value: "#senderlastname#" },
    { elId: 6, value: "#talentinviteurl#" },
    { elId: 7, value: "#jobsuggestionurl#" },
  ],

  templateLanguage: [
    {
      elId: 0,
      value: "All",
    },
    {
      elId: 1,
      value: "English",
    },
    {
      elId: 2,
      value: "Hebrew",
    },
  ],

  conversationLanguage: [
    {
      elId: 1,
      value: "English",
    },
    {
      elId: 2,
      value: "Hebrew",
    },
  ],

  templateGroupTypes: [
    {
      elId: 0,
      value: "All",
    },
    {
      elId: 1,
      value: "Sourcing templates",
    },
    {
      elId: 4,
      value: "Candidates",
    },
    {
      elId: 8,
      value: "Onboarding",
    },
    {
      elId: 9,
      value: "Employers",
    },
    {
      elId: 10,
      value: "Marketing",
    },
  ],

  applicationAccess: [
    {
      elId: 3,
      value: "Recruiter",
    },
    {
      elId: 2,
      value: "Employer",
    },
    {
      elId: 1,
      value: "Disabled",
    },
  ],

  // 4 profile offering --> 5 request to join --> 1 pending --> 2 claimed, 3 deleted -> 6 scraped -> 7 ready to invite -> 8 reinvite
  talentStatus: [
    {
      elId: 0,
      value: "All",
    },
    {
      elId: 2,
      value: "Active",
    },
    // {
    //     elId: 2, value: "Not Active"
    // },
    {
      elId: 1,
      value: "Pending",
    },
    // {
    //     elId: 4, value: "Revoke"
    // },
    {
      elId: 5,
      value: "Request To Join",
    },
    {
      elId: 3,
      value: "Deleted",
    },
    {
      elId: 4,
      value: "Profiling",
    },
    {
      elId: 7,
      value: "Ready to invite",
    },
    {
      elId: 8,
      value: "Reinvite",
    },
  ],

  userStatus: [
    {
      elId: 0,
      value: "All",
    },
    {
      elId: 1,
      value: "Active",
    },
    {
      elId: 2,
      value: "Not active",
    },
    {
      elId: 3,
      value: "Pending",
    },
    {
      elId: 4,
      value: "Revoke",
    },
  ],

  userTypes: [
    { elId: 0, value: "All" },
    { elId: 3, value: "Recruiter" },
    { elId: 2, value: "Employer" },
    { elId: 4, value: "Talent" },
    { elId: 5, value: "Tiro" },
    { elId: 1, value: "Disabled" },
  ],

  userPermissions: [
    { elId: -1, value: "All" },
    { elId: 0, value: "Disabled" },
    { elId: 1, value: "Not Set" },
    { elId: 2, value: "Hr Manager" },
    { elId: 4, value: "Hiring Manager" },
    { elId: 5, value: "Admin" },
  ],

  sources: [
    { elId: 3, value: "Academic" },
    { elId: 29, value: "AllJobs" },
    { elId: 36, value: "Brightsource" },
    { elId: 17, value: "Company Close" },
    { elId: 32, value: "Drushim Portal" },
    { elId: 16, value: "Employment Fair" },
    { elId: 49, value: "Employment Services" },
    { elId: 14, value: "Ethosia Web Site" },
    { elId: 42, value: "Facebook" },
    { elId: 8, value: "Friend" },
    { elId: 26, value: "Internet Campaign" },
    { elId: 28, value: "IVC-Online" },
    { elId: 31, value: "JobMaster" },
    { elId: 27, value: "Jobnet" },
    { elId: 25, value: "Linkedin" },
    { elId: 50, value: "Linkedin Recruiter" },
    { elId: 38, value: "Linkedin Campaign" },
    { elId: 35, value: "Mail Campaign" },
    { elId: 18, value: "Runner" },
  ],

  // 4 profile offering --> 5 request to join --> 1 pending --> 2 claimed, 3 deleted
  invitationStatus: [
    { elId: 1, value: "Pending" },
    { elId: 2, value: "Claimed" },
    { elId: 3, value: "Deleted" },
    { elId: 4, value: "Profiling" },
    { elId: 5, value: "Request to join" },
  ],

  blockedMessageOptions: [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ],

  candidateRanks: [
    { elId: 1, value: "1" },
    { elId: 2, value: "2" },
    { elId: 3, value: "3" },
    { elId: 4, value: "4" },
    { elId: 5, value: "5" },
  ],

  summaryOptions: [
    { label: "With", value: 1 },
    { label: "Without", value: 0 },
    { label: "All", value: -1 },
  ],

  isContactPersonOptions: [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
    { label: "All", value: -1 },
  ],

  linkedinOptions: [
    // 0 = All, 1 = With, 2 = Without
    { label: "With", value: 1 },
    { label: "Without", value: 2 },
    { label: "All", value: 0 },
  ],

  commissionRates: Array.from({ length: 401 }).map((_, i) => ({
    elId: i,
    value: i.toString(),
  })),

  workspaceTypes: [
    { elId: 1, value: "Hybrid" },
    { elId: 2, value: "On-Site" },
    { elId: 3, value: "Remote" },
  ],

  currencies: [
    { elId: 0, value: "NIS", sign: "₪" },
    { elId: 1, value: "USD", sign: "$" },
    { elId: 2, value: "EUR", sign: "€" },
  ],

  noteTags: [
    { elId: 1, value: "Primary" },
    { elId: 2, value: "General" },
    { elId: 3, value: "Conversation" },
    { elId: 4, value: "Phone Interview" },
    { elId: 5, value: "Reaching out" },
    { elId: 6, value: "Meeting " },
  ],

  searchSettingsProjectTypes: [
    {
      elId: 0,
      value: "project",
    },
    {
      elId: 1,
      value: "campaign",
    },
  ],

  recruiterActivityType: [
    { value: "Notes", elId: 1 },
    // {value: "Messages", elId: 2}
  ],

  recruiterActivityInlcude: [
    { value: "Include", elId: 1 },
    { value: "Exclude", elId: 2 },
  ],

  recruiterActivityTime: [
    { value: "Anytime", elId: 0 },
    { value: "Day", elId: 1 },
    { value: "Week", elId: 2 },
    { value: "Month", elId: 3 },
    { value: "3 Months", elId: 4 },
    { value: "6 Months", elId: 5 },
    { value: "1 Year", elId: 6 },
  ],
};


export default data;
