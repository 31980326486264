import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './dashboardActivitiesActivities.scss';
import { observer } from "mobx-react"
import DashboardStatusItem from '../dashboardStatusItem/DashboardStatusItem.component';
import Loader from '../../../../../../components/loader/Loader.component';
import MoreMenuActionComponent from '../../../../../../components/processActions/MoreMenuAction.component';
import processStore from '../../../../../../../stores/process.store';
import utilsService from '../../../../../../../services/utils.service';
import dayjs from 'dayjs';
import data from '../../../../../../../constants/data';

const DashboardActivitiesActivities = observer((props) => {

    const { selectedRecruiterSlugs } = props

    const [statuses, setStatuses] = useState(null);
    const [hiredStatuses, setHiredStatuses] = useState(null);
    const [currentDaysAgo, setCurrentDaysAgo] = useState(1);

    const getDataForMoreMenu = () => {
        const dataForMenu = [
            {
                text: 'Past Day',
                isFunc: true,
                func: () => { setCurrentDaysAgo(1) }
            },
            {
                text: 'Past Week',
                isFunc: true,
                func: () => { setCurrentDaysAgo(7) }
            },
            {
                text: 'Past Month',
                isFunc: true,
                func: () => { setCurrentDaysAgo(30) }
            },
            {
                text: 'Past Year',
                isFunc: true,
                func: () => { setCurrentDaysAgo(365) }
            }
        ];

        //anytime - for now it's disabled
        // if (selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0) {
        //     dataForMenu.push({
        //         text: 'Anytime',
        //         isFunc: true,
        //         func: () => { setCurrentDaysAgo() }
        //     })
        // }

        return dataForMenu;
    }

    
    useEffect(() => {

        let unmounted = false;


        const getAllProcesses = async () => {


            let allProcessesBody = {
                type: 'profiles',
                isAvailable: true
            }
            if (currentDaysAgo) {
                allProcessesBody = { ...allProcessesBody,
                    fromDate: calculateDaysAgoInMills(currentDaysAgo),
                };
            }

            //if not all
            if (selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0) {
                allProcessesBody.recruiterSlugs = selectedRecruiterSlugs;
            }
    
            const allProcesses = await processStore.getProcessesByRecruiter(allProcessesBody);

            if (!unmounted) {
                const filteredProcesses = [];
                allProcesses.forEach(item => {
                    if (!filteredProcesses.some(filter => filter.profile === item.profile &&
                        utilsService.getStatusByEnum(filter.status, true) ===
                        utilsService.getStatusByEnum(item.status, true))) {
                        filteredProcesses.push(item);
                    }
                })
                const statusesResponse = utilsService.getStatusesByProcesses(filteredProcesses);
                setStatuses(statusesResponse);
            }

        }

        const getHiredProcesses = async () => {
 
            let hiredProcessesBody = {
                type: 'profiles',
                stages: [7] // hired
            }
    
            if (currentDaysAgo) {
                hiredProcessesBody = { ...hiredProcessesBody,
                     fromDate: calculateDaysAgoInMills(currentDaysAgo),
                };
            }

            //if not all
            if (selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0) {
                hiredProcessesBody.recruiterSlugs = selectedRecruiterSlugs;
            }
    
            const hiredProcesses = await processStore.getProcessesByRecruiter(hiredProcessesBody);
            if (!unmounted) {
                setHiredStatuses(hiredProcesses.length);
            }
        }

        if (!unmounted) {
            setStatuses(null);
            setHiredStatuses(null);
        }

        getAllProcesses();
        getHiredProcesses();

        return () => {
            unmounted = true;
        }

    }, [selectedRecruiterSlugs, currentDaysAgo])

    
    const getTimeInText = () => {
        switch (currentDaysAgo) {
            case 1:
                return "(Today)"
            case 7:
                return "(Past Week)"
            case 30:
                return "(Past Month)"
            case 365:
                return "(Past year)"

            default:
                return "(Anytime)"
        }
    }

    const calculateDaysAgoInMills = (daysAgo) => {
        const now = dayjs()
        if (daysAgo === 1) {
            return now.subtract(1, 'day').startOf('day').add(1, 'day').valueOf();
        } else if (daysAgo === 7) {
            return now.subtract(1, 'week').startOf('day').valueOf();
        } else if (daysAgo === 30) {
            return now.subtract(1, 'month').startOf('day').valueOf();
        } else if (daysAgo === 365) {
            return now.subtract(1, 'year').startOf('day').valueOf();
        }
    }

    const getUrl = (status) => {
        let stageIds = '';

        if (status === 'In-progress') {
            // request and exposed
            stageIds = '4,5';
        } else {
            const stage = data.stage.find(item => item.value === status);
            stageIds = stage ? stage.elId : '';
        }

        return `/candidates?recruiterId=${selectedRecruiterSlugs.join(',')}&stages=${stageIds}${getPartOfQuery()}`
    }

    const getPartOfQuery = () => {
        if(currentDaysAgo) {
            const startDate = calculateDaysAgoInMills(currentDaysAgo);
            if(startDate) {
                return `&fromDate=${startDate}`
            }
        }
        return '';
    }

    return (
        <div className='dashboard-activities-activities-wrapper'>
            <div className="more-menu">
                <MoreMenuActionComponent
                    process={null}
                    menu={getDataForMoreMenu()}
                    icons={true} />
            </div>

            <h1 className="title">{`Activities ${getTimeInText()}`}</h1>
            <div className="content">
                {!statuses ? <Loader /> :
                    <Fragment>
                        <div className="top1">
                            <div>
                                <DashboardStatusItem number={statuses.leads} name={'Leads'}
                                    linkTo={getUrl('Leads')} />
                            </div>
                            <div />
                            <div>
                                <DashboardStatusItem number={statuses.new} name={'New'}
                                    linkTo={getUrl('New')} />
                            </div>
                            <div />
                            <div>
                                <DashboardStatusItem number={statuses.request}
                                    name={'In Progress'} secondNumber={statuses.exposed}
                                    hasTwoValues={true} linkTo={getUrl('In-progress')} />
                            </div>
                            <div />
                            <div>
                                <DashboardStatusItem number={statuses.decline} name={'Decline'}
                                    linkTo={getUrl('Decline')} />
                            </div>
                        </div>
                        <div className="bottom">
                            <div>
                                <DashboardStatusItem number={statuses.interview} name={'Interview'}
                                    linkTo={getUrl('Interview')} />
                            </div>
                            <div />
                            <div>
                                <DashboardStatusItem number={statuses.offered} name={'Offers'}
                                    linkTo={getUrl('Offered')} />
                            </div>
                            <div />
                            <div>
                                <DashboardStatusItem number={hiredStatuses} name={'Hired'}
                                    linkTo={`${getUrl('Hired')}`} />
                            </div>
                            <div />
                            <div>
                                <DashboardStatusItem number={statuses && statuses.withdrawn} name={'Withdrawn'}
                                    linkTo={getUrl('Withdrawn')} />
                            </div>
                        </div>
                    </Fragment>}

            </div>
        </div>

    )
});

DashboardActivitiesActivities.propTypes = {
    // bla: PropTypes.string,
};

DashboardActivitiesActivities.defaultProps = {
    // bla: 'test',
};

export default DashboardActivitiesActivities;
