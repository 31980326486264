import React from 'react';
import ModalComponent from '../modal/Modal.component';

const ReloadModal = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box confirmation-modal'>
                <header>
                    <h2>{props.title}</h2>
                    {props.subtitle && (
                        <p>{props.subtitle}</p>
                    )}
                </header>

                <footer>
                    {!props.hideCancelButton && (
                        <button
                            onClick={() => props.closeEvent()}
                            className='button gray'>
                            {props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
                        </button>
                    )}
                    <button
                        onClick={e => props.confirmEvent(e, props.data)}
                        className={'button bk-black ' + (props.isLoading ? 'loading' : '')}>
                        {props.buttonText}
                    </button>
                </footer>
            </div>
        </ModalComponent>
    );
};

export default React.memo(ReloadModal);
