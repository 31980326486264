import React, { Component } from 'react';
import './suggestJobsToCandidates.scss';
import SuperSearchUsersProfilesInputComponent from '../forms/multiSelects/SuperSearchUsersProfilesInputComponent';
import SuperSearchJobsInputComponent from '../forms/multiSelects/SuperSearchJobsInput.component';
import searchStore from '../../../stores/search.store';
import profileStore from '../../../stores/profile.store';
import messagingStore from '../../../stores/messaging.store';
import jobStore from '../../../stores/job.store';
import companyStore from '../../../stores/company.store';
import { observer } from 'mobx-react';
import utilsService from '../../../services/utils.service';
import notificationsStore from '../../../stores/notifications.store';

@observer
class SuggestJobsToCandidatesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestionsLoading: false,
            candidatesErrorMessage: '',
            candidatesBlockedMessage: '',
            candidateNamePlaceholderIsMissing: '',
            companyApprovalMessage: '',
            jobsPublishedMessage: '',
            isErrors: false,
            errorsList: []
        };
    }

    closeModal = () => {
        this.props.closeEvent();
    };

    isFormValid = () => {
        const { candidatesBlockedMessage, candidatesErrorMessage, jobsPublishedMessage } = this.state;
        let verdict = true;
        const jobsValid = searchStore.jobsSearchTo.length;

        const profilesValid = searchStore.profilesUsersSearchPersonsTo.length;

        if (!jobsValid || ! profilesValid) {
            verdict = false;
        }

        if(candidatesBlockedMessage || candidatesErrorMessage || jobsPublishedMessage) {
            verdict = false;
        }

        return verdict;
    };

    sendSuggestions = async () => {
        this.setState({suggestionsLoading: true});

        const jobs = searchStore.jobsSearchTo;
        const profiles = searchStore.profilesUsersSearchPersonsTo;

        const jobsSlugArr = jobs.map(job => job.slug);
        const profilesSlugArr = profiles.map(profile => profile.slug);

        const response = await profileStore.sendSuggestions(profilesSlugArr, jobsSlugArr);

        if(response && response.find && response.find(res => res.isError)) {
            const error = response.find(res => res.isError);
            const errorMessage = error.errorMessage ? error.errorMessage : 'Internal Server Error. Please Send Again.';
            notificationsStore.showToast(errorMessage, 'failure');
            this.setState({
                suggestionsLoading: false
            });
        }
        else {
            notificationsStore.showToast(`Suggestions was sent successfully`, 'success');
            this.setState({
                suggestionsLoading: false
            }, () => {
                this.props.requestFinished && this.props.requestFinished('suggest');
                this.props.closeEvent();
            });
        }

    }

    async componentDidMount() {
        const { profiles, jobsSlug } = this.props;

        const tmpProfilesSet = new Set();
        const tmpJobsSet = new Set();

        if (Array.isArray(profiles)) {
            profiles.forEach(profile => tmpProfilesSet.add(profile));
        }

        if (Array.isArray(jobsSlug)) {
            jobsSlug.forEach(jobSlug => tmpJobsSet.add(jobSlug));
        }

        const filteredJobsSlug = [...tmpJobsSet];
        const filteredProfileSet = [...tmpProfilesSet];
        if (filteredProfileSet && filteredProfileSet.length > 0) {
            await profileStore.getProfilesBySlugs(filteredProfileSet)
            searchStore.profilesUsersSearchPersonsTo = filteredProfileSet.map(slug => {
                return profileStore.profiles[slug];
            }).filter(Boolean);
        }

        if (filteredJobsSlug && filteredJobsSlug.length > 0) {
            const companyIdsSet = new Set();
            await jobStore.getJobsBySlugs(filteredJobsSlug);
            filteredJobsSlug.forEach(slug => {
                const job = jobStore.allJobs[slug];
                const company = companyStore.companies[job.companySlug];
                if(!company && job.company) {
                    companyIdsSet.add(job.company);
                }
            });
            
            if(companyIdsSet.size > 0) {
                await companyStore.getCompaniesByIds(Array.from(companyIdsSet));
            }

            const jobsData = filteredJobsSlug.map(slug => {
                const job = jobStore.allJobs[slug];
                const company = companyStore.companies[job.companySlug];

                if (company) {
                    job.companyName = company.name;
                }

                return job;
            });
            searchStore.jobsSearchTo = jobsData;
            let temp = [];
            jobsData.forEach(job => {
                const result = job.emailsForCv && job.emailsForCv.map((item) => {
                    return {
                        elId: item, value: item
                    };
                });
                if(result) {
                    temp = [...temp, ...result];
                }
                
            });
            messagingStore.sendCVEmailListSelected = temp;
            messagingStore.sendCVEmailList = temp;
        }

        this.checkCandidateHaveDataNeeded();

    }

    checkCandidateHaveDataNeeded = async () => {
        const candidates = searchStore.profilesUsersSearchPersonsTo;

        this.setState({ candidatesErrorMessage: '' });
        candidates.forEach(candidate => {
            if (!candidate.phones || candidate.phones.length === 0) {
                this.setState({ candidatesErrorMessage: 'Some candidates are missing phone number.' });
            }
        })

        let isNotPublished = false;
        const jobsToSendTo = searchStore.jobsSearchTo;
        jobsToSendTo.forEach(job => {
            if(job.jobsBoards !== undefined) {
                if(!job.jobsBoards) {
                    isNotPublished = true;
                }
                else if(job.jobsBoards.status === 0) {
                    isNotPublished = true;
                }
            }
        })

        let isBlockedMessage = false;
        const companiesToSendTo = jobsToSendTo.map(job => job.company);
        companiesToSendTo.forEach(async companyToSendTo => {
            candidates.forEach(async candidate => {
                const blockedCompanies = utilsService.getProfileBlockedCompanies(candidate);
                if(blockedCompanies.includes(companyToSendTo)) {
                    isBlockedMessage = true;
                    return false;
                }
            })
        });

        if(isBlockedMessage) {
            this.setState({ candidatesBlockedMessage: 'Some candidates have blocking companies.' });
        }
        else {
            this.setState({ candidatesBlockedMessage: '' });
        }

        if(isNotPublished) {
            this.setState({jobsPublishedMessage: 'Some jobs are not published. You must publish all jobs before sending suggestions!'});
        }
        else {
            this.setState({jobsPublishedMessage: ''});
        }
        this.isFormValid();
    };

    removeMultiItem = text => {
        messagingStore.sendCVEmailListSelected = messagingStore.sendCVEmailListSelected.filter(
            item => item.elId !== text);
    };

    selectMultiItem = item => {
        // If exist then remove
        let update = null;

        if (messagingStore.sendCVEmailListSelected.some(objItem => objItem.elId === item.elId)) {
            update = messagingStore.sendCVEmailListSelected.filter((objItem) => objItem.elId !== item.elId);
        } else {
            update = [...messagingStore.sendCVEmailListSelected, item];
        }

        messagingStore.sendCVEmailListSelected = update;
    };

    componentWillUnmount() {
        messagingStore._sendCVInProcess = false;
        messagingStore.sendCVEmailList = [];
        messagingStore.sendCVEmailListSelected = [];
    }

    onEditorStateChange = suggestBody => {
        this.setState({suggestBody: suggestBody});
    }

    render() {
        const { sendCandidates, jobsSlug, jobLimit } = this.props;
        const { suggestionsLoading, candidatesErrorMessage, candidatesBlockedMessage, companyApprovalMessage, errorsList, isError, jobsPublishedMessage, candidateNamePlaceholderIsMissing } = this.state;

        return (
            <div className='add-to-matches'>
                <h2>Suggest Jobs To Candidates</h2>

                <div className="add-to-matches-job-multi-select item">
                    <label>Job (write job name or use # for job id and @ for company)</label>
                    <SuperSearchJobsInputComponent
                        jobsSlug={jobsSlug}
                        limit={jobLimit}
                        addedNewCandidateHandler={this.checkCandidateHaveDataNeeded}
                    />
                </div>

                <div className="add-to-matches-job-multi-select item last">
                    <label>Candidates</label>
                    <SuperSearchUsersProfilesInputComponent
                        disableUser={true}
                        isValidHandleInParent={true}
                        limit={false}
                        titleType="phone"
                        addedNewCandidateHandler={this.checkCandidateHaveDataNeeded}
                        isCVNeeded={false}
                    />
                    {candidatesErrorMessage && (
                        <div className="err-message">{candidatesErrorMessage}</div>
                    )}
                    {candidatesBlockedMessage && (
                        <div className="err-message">{candidatesBlockedMessage}</div>
                    )}
                    {jobsPublishedMessage && (
                        <div className="err-message">{jobsPublishedMessage}</div>
                    )}
                    {companyApprovalMessage && (
                        <div className="err-message">{companyApprovalMessage}</div>
                    )}
                    {candidateNamePlaceholderIsMissing && (
                        <div className="err-message">{candidateNamePlaceholderIsMissing}</div>
                    )}
                </div>

                {isError && (
                    <div className="add-to-matches-error-list-wrapper">
                        {errorsList.map((item, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="add-to-matches-error-list-item">
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                )}

                {sendCandidates && (
                    <SendCandidatesFooter
                        isFormValid={this.isFormValid()}
                        cancel={this.closeModal}
                        suggestionsLoading={suggestionsLoading}
                        sendToSuggestion={() => this.sendSuggestions()}
                    />
                )}
            </div>
        );
    }
}

export default SuggestJobsToCandidatesComponent;

const SendCandidatesFooter = props => {
    const { cancel, sendToSuggestion, isFormValid, suggestionsLoading } = props;
    let classes = 'button bk-less add-to-leads';
    if (suggestionsLoading) {
        classes += ' loading';
    }

    if (!isFormValid) {
        classes += ' disabled';
    }
    
    return (
        <footer className='candidates-footer'>
            <button
                onClick={cancel}
                className="button gray">Cancel
            </button>
            <button
                onClick={sendToSuggestion}
                className={classes}>Send Suggestions
            </button>
        </footer>
    );
};

