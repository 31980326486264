import React from 'react';
import PropTypes from 'prop-types';
import './dashboardCandidatesTodayJobs.scss';
import { observer } from "mobx-react"
import { DotsIcon } from '../../../../../../components/svg';
import DashboardStatusItem from '../dashboardStatusItem/DashboardStatusItem.component';
import utilsService from '../../../../../../../services/utils.service';
import data from '../../../../../../../constants/data';


const DashboardCandidatesTodayJobs = observer((props) => {

    const { selectedRecruiterSlugs, jobs } = props

    const getStatusUrl = (status) => {
        return `/jobs?recruiterId=${selectedRecruiterSlugs.join(',')}&status=${status}&lastUpdatedStatus=0`
    }

    const todayOpenJobs = jobs && jobs.todayOpenJobs;
    const todayOnHoldJobs = jobs && jobs.todayHoldJobs;
    const todayOnCloseJobs = jobs && jobs.todayCloseJobs;

    const listJobs = [{
        label: "New Jobs",
        number: todayOpenJobs,
        status: `${data.jobStatus.find(item => item.elId === 1).elId}`,
    }, {
        label: "On Hold",
        number: todayOnHoldJobs,
        status: `${data.jobStatus.find(item => item.elId === 2).elId}`,
    }, {
        label: "Closed Jobs",
        number: todayOnCloseJobs,
        status: `${data.jobStatus.find(item => item.elId === 0).elId}`,
    }]

    return (
        <div className='dashboard-candidates-today-jobs-wrapper'>
            <div className="more-menu">
                <DotsIcon />
            </div>

            <h1 className="title">Today Jobs Activities</h1>

            <div className="column">
                {
                    listJobs.map((item, index) => {
                        return (
                            <div key={index}>
                                <DashboardStatusItem 
                                    number={item.number} 
                                    name={item.label} 
                                    linkTo={getStatusUrl(item.status)}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
});

DashboardCandidatesTodayJobs.propTypes = {
    // bla: PropTypes.string,
};

DashboardCandidatesTodayJobs.defaultProps = {
    // bla: 'test',
};

export default DashboardCandidatesTodayJobs
