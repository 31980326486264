import fetchService from '../../../services/fetch.service';
import {QueryParamsBuilder} from '../../../shared/utils/params.utils';

export const reportApiClient = {
  getPipelineReport: (query) => {
    const queryBuilder = new QueryParamsBuilder({
      ...query,
    });

    queryBuilder.setParam('limit');
    queryBuilder.setParam('page');
    queryBuilder.setParam('recruiterId');
    queryBuilder.setParam('fromDate');
    queryBuilder.setParam('toDate');
    queryBuilder.setParam('stage');

    return fetchService.authenticatedGet(`/reports/pipelines?${queryBuilder.build()}`);
  },
  getProcessReport: (query) => {
    const queryBuilder = new QueryParamsBuilder({
      ...query,
    });

    queryBuilder.setParam('limit');
    queryBuilder.setParam('page');
    queryBuilder.setParam('fromDate');
    queryBuilder.setParam('toDate');
    queryBuilder.setParam('interviewer');
    queryBuilder.setParam('jobHandler');
    queryBuilder.setParam('companyHandler');
    queryBuilder.setParam('sender');
    queryBuilder.setParam('jobStatus');
    queryBuilder.setParam('candidateAvailability');
    queryBuilder.setParam('stage');
    queryBuilder.setParam('candidateName');
    queryBuilder.setParam('companyName');
    queryBuilder.setParam('jobTitle');
    queryBuilder.setParam('jobRecruiter');
    queryBuilder.setParam('jobId');
    queryBuilder.setParam('companyId');

    return fetchService.authenticatedGet(`/reports/processes?${queryBuilder.build()}`);
  },
  getBonusReport: (query) => {
    const queryBuilder = new QueryParamsBuilder({
      ...query,
    });

    queryBuilder.setParam('limit');
    queryBuilder.setParam('page');
    queryBuilder.setParam('recruiterId');
    queryBuilder.setParam('month');
    queryBuilder.setParam('projectType');
    queryBuilder.setParam('status');

    return fetchService.authenticatedGet(`/reports/bonus?${queryBuilder.build()}`);
  },
}
