import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';
import utilsService from '../services/utils.service';
import processStore from './process.store';
import profileStore from './profile.store';
import companyStore from './company.store';
import userStore from './user.store';
import logsStore from './logs.store';

class JobStore {
    @observable jobToShow = null;
    @observable jobsFetched = false;
    @observable jobs = {};
    @observable jobsTable = [];
    @observable jobsTableChanged = true;
    @observable allJobs = {};

    @observable searchedJobs = [];
    @observable searchedJobsCount = 0;
    requestNumberJobs = 0;
    requestNumberJobsCount = 0;

    @observable searchedCompanyJobs = [];

    jobsBySingleCompanyLoaded = false;
    recruiterIdForJobs = 0;

    @action.bound
    async getJobs() {
        const jobs = await fetchService.authenticatedGet('/jobs');
        const normalizedData = utilsService.normalizeApiData(jobs.data);
        this.jobs = utilsService.mergeApiData(normalizedData, this.jobs);
        this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
        this.jobsFetched = true;
    }

    @action.bound
    async getJobBySlug(slug) {
        if (this.jobs[slug]) {
            this.jobToShow = this.jobs[slug];
        } else {
            const job = await fetchService.authenticatedGet(`/jobs-slug/${slug}`);
            this.jobs[job.data.slug] = job.data;
            this.allJobs[job.data.slug] = job.data;
            this.jobToShow = job.data;
        }

        return this.jobToShow;
    }

    @action.bound
    async getJobsByIds(ids) {
        const uniqIds = utilsService.uniqArrayValues(ids);
        const filteredIds = utilsService.filterStoreIds(uniqIds, Object.keys(this.jobs));
        if (filteredIds.length > 0) {
            const response = await fetchService.authenticatedGet(`/jobs?ids=${filteredIds.join(',')}`);
            const normalizedData = utilsService.normalizeApiData(response.data);
            this.jobs = utilsService.mergeApiData(normalizedData, this.jobs);
            this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
        }
    }

    findJobSlugById(id) {
        return Object.keys(this.allJobs).find(key => this.allJobs[key]._id === id);
    }

    @action.bound
    async getJobsBySlugs(slugs, isAll) {
        const uniqIds = utilsService.uniqArrayValues(slugs);
        const filteredIds = utilsService.filterStoreIds(uniqIds, Object.keys(this.jobs));
        if (filteredIds.length > 0) {

            const chunks = utilsService.splitArrayIntoChunks(filteredIds, 30);

            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/jobs?slugs=${chunk.join(',')}`);;
            });

            const response = await Promise.all(promises);
            let data = [];
            response.forEach(resp => {
                data = [...data, ...resp.data];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            if(!isAll){
                this.jobs = utilsService.mergeApiData(normalizedData, this.jobs);
            }
            this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
        }
    }

    @action.bound
    async getRecruiterJobsByCompany(companyId, isAll, jobsBySingleCompanyLoadedNeeded = true, statuses) {
        let result = null;
        if (this.jobsBySingleCompanyLoaded && jobsBySingleCompanyLoadedNeeded) {
            const jobs = isAll ? this.allJobs : this.jobs;
            result = Object.values(jobs).filter(j => j.company === companyId);
        } else {
            let url = `/jobs-company?companyId=${companyId}`;
            if(statuses) {
                url += `&statuses=${statuses}`
            }
            const response = await fetchService.authenticatedGet(url);
            const normalizedData = utilsService.normalizeApiData(response.data);
            if (!isAll) {
                this.jobs = utilsService.mergeApiData(normalizedData, this.jobs);
            }
            this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
            if (jobsBySingleCompanyLoadedNeeded) {
                this.jobsBySingleCompanyLoaded = true;
            }
            result = response.data;
        }

        return result;
    }

    @action.bound
    async getRecruiterJobsByRecruiter(recId) {
        let _id = recId;
        if (!_id) {
            _id = userStore.user._id;
        }
        const response = await fetchService.authenticatedGet(`/jobs-recruiter?recId=${_id}`);
        const normalizedData = utilsService.normalizeApiData(response.data);
        this.jobs = utilsService.mergeApiData(normalizedData, this.jobs);
        this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
        return response.data;
    }

    @action.bound
    async getJobsByUser(userId) {
        const response = await fetchService.authenticatedGet(`/jobs/by-users/${userId}`);
        return response?.data;
    }

    @action.bound
    async queryJobs(body) {
        const response = await fetchService.authenticatedPost(`/jobs-query`, body);
        return response.data;
    }

    @action.bound
    setJobsTable(table) {
        this.jobsTable = table;
    }

    getJobSlugs() {
        return Object.keys(this.jobs);
    }

    @action.bound
    async closeJob(jobSlug, talentId) {
        try {
            const body = {
                talentId: talentId
            };
            const status = await fetchService.authenticatedPut(`/job/${jobSlug}/close/`, body);
            if (status && status.data) {
                await this.getJobs();
                await this.buildGridData();
                processStore.processesPipelinesGridAll = [];

                // update logs
                const job = this.jobs[jobSlug];
                if(job._id) {
                    const body = {
                        jobId: job._id
                    }
                    logsStore.getJobsStatus(body);
                }

                return status.data.IsError;
            }

            return false;
        } catch (err) {
            return false;
        }
    }

    @action.bound
    async createJob(data) {
        let isError = false;
        const job = await fetchService.authenticatedPost(`/jobs`, data);
        if (job.data.isError) {
            isError = job.data.errorMessage;
        } else {

            this.jobs[job.data.slug] = job.data;

            // update logs
            const body = {
                jobId: job._id
            }
            logsStore.getJobsStatus(body);
        }
        return {isError: isError, job: job.data};
    }

    @action.bound
    async updateJob(id, data) {
        let isError = false;
        const job = await fetchService.authenticatedPut(`/jobs/${id}`, data);
        if (!job || !job.data || job.data.isError) {
            isError = job && job.data ? job.data.errorMessage : 'Unknown error';
        } else {
            
            this.jobs[job.data.slug] = job.data;
            this.allJobs[job.data.slug] = job.data;
            this.jobToShow = job.data;

            // update logs
            const body = {
                jobId: job.data._id
            }
            logsStore.getJobsStatus(body);
        }
        return {isError: isError, job: job && job.data};
    }

    @action.bound
    buildGridData = async () => {
        const jobs = this.jobs;
        const isRecruiter = utilsService.isRecruiter();
        let keys = Object.keys(jobs);
        if (isRecruiter) {
            const recId = userStore.user._id;
            keys = keys.filter(key => jobs[key].recruiterInChargeId === recId);
        }
        let tempArr = [];
        const companiesIds = new Set();
        const recruiterIds = new Set();

        const processesKeys = Object.keys(processStore.processes);

        if (processesKeys.length === 0) {
            keys.forEach(key => {
                companiesIds.add(jobs[key].companySlug);
                if (jobs[key].recruiterInChargeId) {
                    recruiterIds.add(jobs[key].recruiterInChargeId);
                }
            });

            const promisses = [
                this.getProcessesFromApi(),
                this.getRecruitersFromApi(jobs, keys),
                userStore.getUsersByIds(Array.from(recruiterIds))
            ];

            if (companiesIds.size > 0) {
                promisses.push(companyStore.getCompaniesBySlugs(Array.from(companiesIds)));
            }

            await Promise.all(promisses);
        } else {
            keys.forEach(key => {
                recruiterIds.add(jobs[key].recruiterInChargeId);
            });
            await userStore.getUsersByIds(Array.from(recruiterIds));
        }

        const jobsProcesses = this.calculateJobsProcesses(jobs, keys);

        if (keys.length > 0) {
            tempArr = keys.map((key) => {
                const job = jobs[key];
                const company = companyStore.companies[job.companySlug];
                let recruiterName = '';
                if (job.recruiterInChargeSlug) {
                    const user = userStore.users[job.recruiterInChargeSlug];
                    if (user) {
                        recruiterName = utilsService.getDisplayName(user);
                    }
                }
                return {
                    id: job._id,
                    slug: job.slug,
                    position: job.title,
                    isDiscreet: !!job.isDiscreet,
                    positionType: job.positionType ? job.positionType.value : '',
                    update: utilsService.getPassedDaysFromDate(
                        Number(job.dateUpdate)),
                    recruiter: recruiterName,
                    'in-progress': jobsProcesses[job.slug]['in-progress'],
                    new: jobsProcesses[job.slug].new,
                    interview: jobsProcesses[job.slug].interview,
                    offered: jobsProcesses[job.slug].offered,
                    hired: jobsProcesses[job.slug].hired,
                    leads: jobsProcesses[job.slug].leads,
                    dateCreated: job.dateCreated,
                    isOpen: job.isOpen,
                    status: job.status,
                    companyName: company ? company.name : '',
                    companyImage: utilsService.getCompanyImage(company)
                };
            });
        }
        this.jobsTable = tempArr;
    };

    getRecruitersFromApi = (jobs, jobKeys) => {
        const recruiterIds = new Set();
        jobKeys.forEach(key => {
            if (jobs[key].hrManagers && jobs[key].hrManagers.length > 0) {
                recruiterIds.add(jobs[key].hrManagers[0]);
            }
        });

        return profileStore.getProfilesByIds(Array.from(recruiterIds));
    };

    getProcessesFromApi() {
        const jobSlugs = jobStore.getJobSlugs();
        return processStore.getProcesses(true, jobSlugs);
    };

    calculateJobsProcesses(jobs, jobKeys) {
        const tempObj = {};
        const processes = processStore.processes;
        const procKeys = Object.keys(processes);

        jobKeys.forEach(key => {
            tempObj[key] = {
                new: 0,
                interview: 0,
                'in-progress': 0,
                offered: 0,
                hired: 0,
                terminated: 0,
                leads: 0
            };
        });

        procKeys.forEach(key => {
            const statusUp = utilsService.getStatusByEnum(processes[key].status);
            const jobId = processes[key].jobSlug;
            if (statusUp && jobId) {
                const status = statusUp.toLowerCase();
                const job = tempObj[jobId];
                if (job) {
                    job[status] = tempObj[jobId][status] + 1;
                }
            }
        });
        return tempObj;
    };

    @action.bound
    appendDataFromSearch(data) {
        const normalizedData = utilsService.normalizeApiData(data);
        this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
    }

    @action.bound
    async setRecruiterJobs(item) {
        this.recruiterIdForJobs = item.slug;
        this.jobToShow = null;
        this.jobsFetched = false;
        this.jobs = {};
        await this.getRecruiterJobsByRecruiter(item._id);
        await this.buildGridData();
    }

    @action.bound
    mergeData(newData, toJobs=false){
        const normalizedData = utilsService.normalizeApiData(newData);
        if(toJobs){
            this.jobs = utilsService.mergeApiData(normalizedData, this.jobs);
        }
        this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
    }

    @action.bound
    async searchJobsWithMatches(filters, isCount = false, isCompany = false) {
        if (isCount) {
            const response = await fetchService.authenticatedPost('/search/jobs-with-matches', {
                ...filters,
                count: true,
                requestNumber: ++this.requestNumberJobsCount
            });

            const data = response?.data;

            if (this.requestNumberJobsCount === data?.requestNumber) {
                this.searchedJobsCount = data?.counter || 0;
            }
        } else {
            const response = await fetchService.authenticatedPost('/search/jobs-with-matches', {
                ...filters,
                limit: 50,
                requestNumber: ++this.requestNumberJobs,
            });

            const data = response?.data;

            if (this.requestNumberJobs === data?.requestNumber) {
                this[isCompany ? 'searchedCompanyJobs' : 'searchedJobs'] = data?.jobs || [];
            }
        }
    }

    @action.bound
    async searchJobsByIds(ids) {
        const filteredIds = utilsService.filterUniqIds(ids, Object.values(this.jobs));
        
        if (filteredIds.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(filteredIds, 30);
            const promises = chunks.map(chunk => fetchService.authenticatedPost('/search/jobs', { jobs: chunk }));
            const response = await Promise.all(promises);

            let data = [];
            response.forEach(resp => {
                data = [...data, ...resp.data];
            });
            
            const normalizedData = utilsService.normalizeApiData(data);
            this.jobs = utilsService.mergeApiData(normalizedData, this.jobs);
            this.allJobs = utilsService.mergeApiData(normalizedData, this.allJobs);
        }
    }

    @action.bound
    async fetchPrecomputedJobWishlistMatching(job_slug) {
      try {
        const resp = await fetchService.authenticatedGet(`/v1/jobs/wishlist-topcandidates/${job_slug}`);
        const details = resp.data.details;
        
        if (!details || !details.matches) {
          this.wishlistCandidateCount = 0;
          this.wishlistCandidates = [];
          return { matches: [], flag: null };
        }
        
        this.wishlistCandidateCount = details.matches.length;
        this.wishlistCandidates = details.matches.map(match => match.profileSlug);
        return { matches: details.matches, flag: details.flag };
      } catch (error) {
        console.error("Error in fetchPrecomputedJobWishlistMatching:", error);
        throw error;
      }
    }
    
    @action.bound
    async fetchJobWishlistMatching(job_slug) {
      try {
        const resp = await fetchService.authenticatedGet(`/v1/jobs/wishlist-matching/${job_slug}`);
        // Use 'details' based on the API response structure:
        const details = resp.data.details;
        const candidateSlugs = details.map(item => item["Candidate Slug"] || item.profileSlug);
        const wishlistMatchCount = candidateSlugs.length;
        this.processesPipelinesStatusCounter = {
          ...this.processesPipelinesStatusCounter,
          wishlistMatch: wishlistMatchCount,
        };
        this.wishlistCandidates = candidateSlugs;
        // Return the raw details so the caller can map over objects
        return details;
      } catch (error) {
        console.error("Error in fetchJobWishlistMatching:", error);
        throw error;
      }
    }

    @action.bound
    async fetchPrecomputedJobRecruiterMatching(job_slug) {
      try {
        const resp = await fetchService.authenticatedGet(`/v1/jobs/recruiter-topcandidates/${job_slug}`);
        const details = resp.data.details;
        
        if (!details || !details.matches) {
          this.recruiterCandidateCount = 0;
          this.recruiterCandidates = [];
          return { matches: [], flag: null };
        }
        
        this.recruiterCandidateCount = details.matches.length;
        this.recruiterCandidates = details.matches.map(match => match.profileSlug);
        return { matches: details.matches, flag: details.flag };
      } catch (error) {
        console.error("Error in fetchPrecomputedJobRecruiterMatching:", error);
        throw error;
      }
    }
    
    @action.bound
    async fetchJobRecruiterMatching(job_slug) {
      try {
        const resp = await fetchService.authenticatedGet(`/v1/jobs/recruiter-matching/${job_slug}`);
        // Use 'details' based on the API response structure:
        const details = resp.data.details;
        const candidateSlugs = details.map(item => item["Candidate Slug"] || item.profileSlug);
        const recruiterMatchCount = candidateSlugs.length;
        this.processesPipelinesStatusCounter = {
          ...this.processesPipelinesStatusCounter,
          recruiterMatch: recruiterMatchCount,
        };
        this.recruiterCandidates = candidateSlugs;
        // Return the raw details so the caller can map over objects
        return details;
      } catch (error) {
        console.error("Error in fetchJobRecruiterMatching:", error);
        throw error;
      }
    }

    @action.bound
    async fetchPrecomputedJobLinkedinMatching(job_slug) {
      try {
        const resp = await fetchService.authenticatedGet(`/v1/jobs/linkedin-topcandidates/${job_slug}`);
        const details = resp.data.details;
        
        if (!details || !details.matches) {
          this.linkedinCandidateCount = 0;
          this.linkedinCandidates = [];
          return { matches: [], flag: null };
        }
        
        this.linkedinCandidateCount = details.matches.length;
        this.linkedinCandidates = details.matches.map(match => match.profileSlug);
        return { matches: details.matches, flag: details.flag };
      } catch (error) {
        console.error("Error in fetchPrecomputedJobLinkedinMatching:", error);
        throw error;
      }
    }

    @action.bound
    async fetchJobLinkedinMatching(job_slug) {
      try {
        const resp = await fetchService.authenticatedGet(`/v1/jobs/linkedin-matching/${job_slug}`);
        // Use 'details' based on the API response structure:
        const details = resp.data.details;
        const candidateSlugs = details.map(item => item["Candidate Slug"] || item.profileSlug);
        const linkedinMatchCount = candidateSlugs.length;
        this.processesPipelinesStatusCounter = {
          ...this.processesPipelinesStatusCounter,
          linkedinMatch: linkedinMatchCount,
        };
        this.linkedinCandidates = candidateSlugs;
        // Return the raw details so the caller can map over objects
        return details;
      } catch (error) {
        console.error("Error in fetchJobLinkedinMatching:", error);
        throw error;
      }
    }
    
}

const jobStore = new JobStore();

export default jobStore;
